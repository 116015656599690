<template>
  <div class="mt-3">
    <!-- Recursive monitoring start button,
             Button to trigger modal -->
    <v-btn
      v-if="membersToMonitor.length > 1"
      @click="start_monitoring(membersToMonitor)"
      class="allrefresh"
      >Recursive Monitoring</v-btn
    >
    <v-dialog v-model="dialog" max-width="1000" class="pa-0 white-bg">
      <v-card>
        <v-card-title class="headline white lighten-2">
          {{ current_user.name }} Is Working On {{ current_user.project_name }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-spacer></v-spacer>

        <Loader v-if="loading || !instant_capture_src" class="cap-loader" />
        <v-img
          :src="instant_capture_src"
          class="img-fluid w-100"
          width="1000"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { sha256 } from "js-sha256";
import { mapState } from "vuex";
export default {
  name: "RecursiveMonitoring",
  components: {
    Loader: () =>
      import("@/components/TeamMembers/RealTimeMonitor/CreateVideoLoader"),
  },
  props: ["recursiveEmployees"],
  data() {
    return {
      membersToMonitor: [],
      close_modal: false,
      loading: false,
      instant_capture_src: "",
      current_user: "",
      dialog: false,
    };
  },
  mounted() {
    this.onlineMembers();
  },
  sockets: {
    // Real time monitoring response
    real_time_monitoring(image, user_id) {
      if (user_id == this.current_user_monitor_id) {
        console.log("matched", user_id);
        this.instant_capture_src = image;
      } else {
        this.instant_capture_src = "";
      }
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
    keyData() {
      if (this.$store.state.auth.user.id) {
        return sha256(this.$store.state.auth.user.id);
      }
    },
    Keys() {
      this.getAllKeys = this.$store.state.pf.userKeyData;
      if (this.getAllKeys.includes(this.keyData)) {
        return true;
      }
    },
  },
  methods: {
    // Starts recursive monitoring
    start_monitoring(members) {
      this.dialog = true;
      this.close_modal = false;
      this.start_recursive_monitoring(members);
    },
    // Creates a deep copy of onlineMembers
    onlineMembers() {
      let onlineMembers;
      this.membersToMonitor = [];
      onlineMembers = this.recursiveEmployees;
      onlineMembers.map((data) => {
        this.membersToMonitor.push(data);
      });
    },
    // Starts real time monitoring for the first member of online employees in case of open modal
    start_recursive_monitoring(users) {
      if (users.length != 0 && !this.close_modal) {
        this.current_user = users[0];
        this.current_user_monitor_id = users[0].id;
        this.real_time_req(users);
      } else if (this.Keys && this.dialog == true) {
        {
          this.onlineMembers();
          this.start_monitoring(this.membersToMonitor);
        }
      } else {
        this.closeModal();
        this.users = this.membersToMonitor;
      }
    },
    // Emits real time monitoring call to sockets and stops it after 10 seconds
    real_time_req(users) {
      return new Promise((resolve) => {
        this.$store.commit("custom/set_real_time_user", users[0].id);
        let company_id = this.$route.params.id;
        let user_id = this.user.id;
        let memberid = users[0].id;
        let auth_token = this.user.auth_token;
        this.$socket.emit("real_time_monitoring", {
          user_id,
          memberid,
          company_id,
          auth_token,
        });
        setTimeout(() => {
          resolve(
            this.$socket.emit("stop_real_time_monitor", users[0].id),
            this.start_recursive_monitoring(users.splice(1))
          );
        }, 10000);
      });
    },
    closeModal() {
      this.dialog = false;
      this.onlineMembers();
      this.$socket.emit("stop_real_time_monitor", this.current_user_monitor_id);
      this.close_modal = true;
    },
  },
};
</script>
<style scoped>
.allrefresh {
  color: white !important;
  background-color: #2758f6 !important;
  border: none !important;
}
.img-style {
  width: 100%;
  max-height: 65vh;
}
/deep/ .modal-content {
  margin-top: 200px;
}
</style>
